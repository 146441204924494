function SidebarLabel(props: { icon: JSX.Element; label: string }) {
  const { icon, label } = props;
  return (
    <div className="flex gap-2.5 items-center">
      {icon}
      <span className="hidden lg:block">{label}</span>
    </div>
  );
}

export default SidebarLabel;
