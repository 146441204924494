import { usePostDetailsModalContext } from '@/context/PostDetailsModalContext';
import React from 'react';

function RightSideBar() {
  const { postDetailsModalProps } = usePostDetailsModalContext();
  return (
    <div className={`pl-4 ${postDetailsModalProps.bgOpacity}`}>
      RightSideBar
    </div>
  );
}

export default RightSideBar;
