// src/hooks/useFetchAvatar.ts
import { useState, useEffect } from 'react';
import httpClient from '@/utils/httpClient';
import { USER_AVATAR_ENDPOINT } from '@/utils/apiEndpoints';

export function useFetchAvatar(filename: string | null) {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!filename) return;

    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchAvatar() {
      try {
        const req = await httpClient(
          'GET',
          `${USER_AVATAR_ENDPOINT}/${filename}`,
          null,
          {
            responseType: 'blob', // Important to get the image as a blob
            signal, // Pass the signal to the request
          },
        );
        if (req.status === 200) {
          const url = URL.createObjectURL(req.data);
          setAvatarUrl(url);
        }
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError('Error fetching avatar');
          console.error('Error fetching avatar:', err);
        }
      }
    }

    fetchAvatar();

    return () => {
      controller.abort();
    };
  }, [filename]);

  return { avatarUrl, error };
}
