import { useEffect } from 'react';

const useColorScheme = () => {
  useEffect(() => {
    const rootElement = document.getElementById('root');

    const updateBackgroundColor = () => {
      if (!rootElement) return;
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        rootElement.style.backgroundColor = '#111'; // Dark mode
        rootElement.style.color = '#fff'; // Dark mode text color
      } else {
        rootElement.style.backgroundColor = '#fff'; // Reset to default or light mode
        rootElement.style.color = '#000'; // Reset to default or light mode text color
      }
    };

    // Initial check
    updateBackgroundColor();

    // Listen for changes in the color scheme
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', updateBackgroundColor);
    // Log current color scheme
    console.log(
      `Current color scheme: ${window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'}`,
    );
    // Cleanup listener on component unmount
    return () =>
      mediaQuery.removeEventListener('change', updateBackgroundColor);
  }, []);
};

export default useColorScheme;
