const host = `${import.meta.env.VITE_BACKEND_HOST_URL}`;

export const AUTH_SIGNUP_ENDPOINT = `${host}/auth/signup`;
export const AUTH_SIGNIN_ENDPOINT = `${host}/auth/signin`;
export const AUTH_VERIFY_TOKEN_ENDPOINT = `${host}/auth/verifyToken`;
export const USER_PROFILE_ENDPOINT = `${host}/profile`;

export const CREATE_POST_ENDPOINT = `${host}/createPost`;
export const USER_UPDATE_AVATAR_ENDPOINT = `${host}/profile/updateAvatar`;
export const USER_AVATAR_ENDPOINT = `${host}/profile/avatar`;
export const USER_POST_MEDIA_ENDPOINT = `${host}/profile/postMedia`;
export const USER_LATEST_POSTS_ENDPOINT = `${host}/profile/latestPosts`;
