function InputButton(props: {
  inputType: string;
  inputPlaceholder: string;
  inputValue: string;
  inputChangeListener(input: string): void;
}) {
  const { inputType, inputPlaceholder, inputValue, inputChangeListener } =
    props;
  return (
    <input
      type={inputType}
      placeholder={inputPlaceholder}
      value={inputValue}
      onChange={(e) => inputChangeListener(e.target.value)}
      className="w-full max-w-[220px] md:max-w-[300px] xl:max-w-[400px] pl-2 pr-4 py-2.5 font-light text-sm rounded-md border-none  
      focus:outline-none focus:ring-1 focus:ring-zinc-400/60
      bg-zinc-500  text-white/90 placeholder-gray-800/80 duration-700"
    ></input>
  );
}

export default InputButton;
