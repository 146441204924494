import Home1 from '@/pages/Home1';
import { createContext, ReactNode, useContext, useState } from 'react';

interface NavContextProps {
  selectedNode: ReactNode;
  setSelectedNode: (node: ReactNode) => void;
  rightSideBarVisible: boolean;
  setRightSideBarVisible: (rightSideBarVisible: boolean) => void;
  setshowMaxFileUploadToast: (showMaxFileUploadToast: boolean) => void;
  showMaxFileUploadToast: boolean;
}

const NavContext = createContext({} as NavContextProps);

export function useNav() {
  return useContext(NavContext);
}
export function NavProvider(props: Readonly<{ children: ReactNode }>) {
  const { children } = props;
  const [selectedNode, setSelectedNode] = useState<ReactNode>(<Home1 />);
  const [rightSideBarVisible, setRightSideBarVisible] = useState(true);
  const [showMaxFileUploadToast, setShowMaxFileUploadToast] = useState(false);
  useState(false);

  return (
    <NavContext.Provider
      value={{
        selectedNode,
        setSelectedNode,
        rightSideBarVisible,
        setRightSideBarVisible,
        showMaxFileUploadToast,
        setshowMaxFileUploadToast: setShowMaxFileUploadToast,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}
