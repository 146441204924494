import { useUser } from '@/context/UserContext';
import { useFetchAvatar } from '@/hooks/useFetchAvatar';
import { useFetchPostMedia } from '@/hooks/useFetchPostMedia';
import SigninComponent from '@/reactComponents/SigninComponent';
import {
  USER_UPDATE_AVATAR_ENDPOINT,
  USER_PROFILE_ENDPOINT,
  USER_LATEST_POSTS_ENDPOINT,
} from '@/utils/apiEndpoints';
import httpClient from '@/utils/httpClient';
import { Camera, Copy, Video } from 'lucide-react';

import { useEffect, useRef, useState } from 'react';

type ProfileDetails = {
  firstName: string;
  lastName: string;
  avatar: string;
  postsCount: number;
  emailVerified: boolean;
};

type PostDetails = {
  id: number;
  text: string;
  mediaFiles: { fileName: string; mimeType: string }[];
};

function Profile1() {
  const { getUser } = useUser();
  const [profileDetails, setProfileDetails] = useState<ProfileDetails | null>(
    null,
  );

  useEffect(() => {
    async function fetchPostsCount() {
      const fetch = await httpClient('GET', USER_PROFILE_ENDPOINT, null, {
        withCredentials: true,
      });
      if (fetch.status === 200) {
        const { firstName, lastName, avatar, postsCount, emailVerified } =
          fetch.data;
        setProfileDetails({
          firstName,
          lastName,
          avatar,
          postsCount,
          emailVerified,
        });
      } else {
        console.error('Error fetching profile details:', fetch);
      }
    }
    if (getUser) fetchPostsCount();
  }, [getUser]);

  if (!getUser)
    return <SigninComponent message="sign-in to view your profile" />;
  return (
    <>
      <div className="flex py-8 justify-between">
        <div>
          <RenderDP />
        </div>
        <div className="flex items-center">
          {profileDetails && (
            <div className="flex flex-col gap-2">
              <p>
                {profileDetails.firstName} {} {profileDetails.lastName}
              </p>

              {profileDetails.emailVerified && <p>e-mail verified </p>}
              {!profileDetails.emailVerified && <p>e-mail not verified </p>}

              <RenderPostsCount postsCount={profileDetails?.postsCount} />
            </div>
          )}
        </div>
      </div>
      <RenderLatestPosts />
    </>
  );
}

function RenderLatestPosts() {
  const [posts, setPosts] = useState<PostDetails[]>([]);
  useEffect(() => {
    async function fetchLatestPosts() {
      const req = await httpClient(
        'GET',
        `${USER_LATEST_POSTS_ENDPOINT}/0/6`,
        null,
        {
          withCredentials: true,
        },
      );
      console.log('req:', req);
      if (req.status === 200) setPosts(req.data);
    }
    fetchLatestPosts();
  }, []);

  return (
    <div className="flex flex-col items-center">
      Latest Posts
      <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 gap-4 py-4 px-2">
        {posts.map((post: PostDetails) => (
          <RenderPost key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
}

function RenderPost(props: { post: PostDetails }) {
  const { post } = props;
  const { mediaFiles } = post;

  const fileName = mediaFiles[0].fileName;
  const fileType = mediaFiles[0].mimeType.split('/')[0];
  const multipleFiles = mediaFiles.length > 1;
  const { postMediaUrl, error } = useFetchPostMedia(fileName);

  const [preview, setPreview] = useState<string | null>(null);
  useEffect(() => {
    if (postMediaUrl) {
      setPreview(postMediaUrl);
    }
  }, [postMediaUrl]);

  if (error) {
    console.error('Error fetching post media:', error);
  }

  return (
    <div className="relative cursor-pointer hover:opacity-70">
      {preview && fileType === 'image' && (
        <div className="relative ">
          <img
            src={preview}
            alt="post media"
            className="w-80 h-80  object-cover rounded-lg"
          />
          {multipleFiles && (
            <Copy
              size={20}
              strokeWidth={1.8}
              className="absolute bottom-2 left-1.5"
            />
          )}
        </div>
      )}
      {preview && fileType === 'video' && (
        <div className="relative cursor-pointer">
          <video
            src={preview}
            muted
            className="w-80 h-80 object-cover rounded-lg"
            controls={false}
          />

          <Video
            size={36}
            fill="#fff"
            fillOpacity={0.8}
            strokeWidth={0.8}
            className="absolute top-1 right-1.5"
          />
          {multipleFiles && (
            <Copy
              size={20}
              strokeWidth={1.8}
              className="absolute bottom-2 left-1.5"
            />
          )}
        </div>
      )}
      {/* <div className="absolute bottom-0 left-0 w-80  bg-black/30 backdrop-blur-lg text-white text-xs font-light p-2 truncate rounded-b-lg">
        {text}
      </div> */}
    </div>
  );
}

function RenderPostsCount(props: { postsCount: number }) {
  const { postsCount } = props || 0;

  return (
    <div className="flex items-center text-sm font-medium">
      {postsCount}
      <span className="text-sm ml-1 font-normal ">posts</span>
    </div>
  );
}

function RenderDP() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const { getUser } = useUser();
  const { avatarUrl, error } = useFetchAvatar(getUser?.avatar || null);

  if (error) {
    console.error('Error fetching avatar:', error);
  }

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log('Selected file:', file);
      const fileURL = URL.createObjectURL(file);
      setPreview(fileURL);

      const formData = new FormData();

      formData.append('avatar', file);

      const req = await httpClient(
        'POST',
        USER_UPDATE_AVATAR_ENDPOINT,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        },
      );
      console.log('req:', req);
    }
  };

  useEffect(() => {
    console.log('useEffect called with avatarUrl:', avatarUrl);

    if (avatarUrl) {
      setPreview(avatarUrl);
    }
  }, [avatarUrl]);

  return (
    <div
      className="relative flex w-32 h-32 justify-center items-center rounded-full bg-zinc-400 cursor-pointer"
      onClick={handleClick}
    >
      {preview ? (
        <>
          <img
            src={preview}
            alt="Selected Preview"
            className="w-full h-full object-cover rounded-full"
          />
          <div
            className="absolute bg-white/40 w-full h-full rounded-full"
            title="Change Profile Photo"
          >
            <Camera
              size={60}
              fill="#fff"
              color="#fff"
              strokeWidth={0}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
            <div
              className="absolute top-[47%] left-[55%] transform translate-x-1/2 
              bg-amber-900 w-[4.5px] h-[3px] rounded-full"
            ></div>
            <div
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
             border-2 border-amber-900 w-3 h-3 rounded-full"
            ></div>
          </div>
        </>
      ) : (
        <div className="bg-zinc-500/60 rounded-full p-12">
          <Camera
            size={45}
            fill="#fff"
            color="#fff"
            strokeWidth={0}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
          <div
            className="absolute top-[47%] left-[55%] transform translate-x-1/2 
              bg-amber-900 w-[4.5px] h-[3px] rounded-full"
          ></div>
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
             border-2 border-amber-900 w-3 h-3 rounded-full"
          ></div>
        </div>
      )}

      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );
}

export default Profile1;
