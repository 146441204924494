import React, { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';
import ReactPlayer from 'react-player';
import { Card, CardContent } from '@/components/ui/card';
import { Play, X, ChevronLeft, ChevronRight } from 'lucide-react';

export function ImageCarousel(props: {
  files: File[];
  handleRemoveFile: (index: number, event: React.MouseEvent) => void;
  handlePlayClick: (index: number) => void;
  playing: { [key: number]: boolean };
  isSubmitting: boolean;
}) {
  const { files, handleRemoveFile, handlePlayClick, playing, isSubmitting } =
    props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (isSubmitting) return;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % files.length);
  };

  const handlePrevious = () => {
    if (isSubmitting) return;
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + files.length) % files.length,
    );
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [files]);

  return (
    <div className="relative w-full max-w-xs">
      <Carousel className="w-full">
        <CarouselContent>
          {files.map((file, index) => (
            <CarouselItem
              key={index}
              className={`relative ${index === currentIndex ? 'block' : 'hidden'}`}
            >
              <div className="p-0">
                <Card className="border-none p-0 m-0">
                  <CardContent className="flex aspect-square items-center justify-center p-0 rounded-lg">
                    {file.type.startsWith('image/') ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Image ${index + 1}`}
                        className="rounded-lg w-full h-full object-cover"
                      />
                    ) : (
                      <div className="relative w-full h-full">
                        <ReactPlayer
                          url={URL.createObjectURL(file)}
                          playing={playing[index] || false}
                          width="100%"
                          height="100%"
                          className="rounded-lg"
                          muted
                        />
                        {!playing[index] && (
                          <button
                            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg cursor-pointer"
                            onClick={() => handlePlayClick(index)}
                          >
                            <Play size={48} color="white" />
                          </button>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      {!isSubmitting && (
        <button
          className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
          onClick={(event) => handleRemoveFile(currentIndex, event)}
        >
          <X size={16} />
        </button>
      )}
      {!isSubmitting && files.length > 1 && (
        <button
          type="button"
          className="carousel-previous absolute -left-[15px] top-1/2 transform -translate-y-1/2 box-border"
          disabled={isSubmitting}
          onClick={handlePrevious}
        >
          <ChevronLeft
            strokeWidth={1.2}
            size={30}
            color="#222"
            className="bg-white rounded-full p-0.5  shadow-lg"
          />
        </button>
      )}
      {!isSubmitting && files.length > 1 && (
        <button
          type="button"
          className="carousel-next absolute -right-[15px] top-1/2 transform -translate-y-1/2"
          disabled={isSubmitting}
          onClick={handleNext}
        >
          <ChevronRight
            strokeWidth={1}
            size={30}
            color="#222"
            className="bg-white rounded-full p-0.5  shadow-lg"
          />
        </button>
      )}
    </div>
  );
}
