import React from 'react';
import Feed from './Feed';

function Home1() {
  return (
    <div>
      <Feed />
    </div>
  );
}

export default Home1;
