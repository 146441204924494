import { useNav } from '@/context/NavContext';

import Home1 from './Home1';
import Search from './Search';
import Messages from './Messages';
import Dashboard from './Dashboard';

import Post from './Post';
import SidebarLabel from '@/reactComponents/SidebarLabel';
import {
  Home,
  LayoutDashboard,
  MessageSquare,
  SearchIcon,
  SquarePlus,
  User,
} from 'lucide-react';
import Profile1 from './Profile1';
import { usePostDetailsModalContext } from '@/context/PostDetailsModalContext';

const iconStrokeWidth = 1.23;

function SideBar() {
  const navPages = [
    {
      key: 'home',
      element: (
        <SidebarLabel
          icon={<Home strokeWidth={iconStrokeWidth} />}
          label="Home"
        />
      ),
      node: <Home1 />,
      rightSideBarVisible: true,
    },
    {
      key: 'search',
      element: (
        <SidebarLabel
          icon={<SearchIcon strokeWidth={iconStrokeWidth} />}
          label="Search"
        />
      ),
      node: <Search />,
      rightSideBarVisible: false,
    },
    {
      key: 'messages',
      element: (
        <SidebarLabel
          icon={<MessageSquare strokeWidth={iconStrokeWidth} />}
          label="Messages"
        />
      ),
      node: <Messages />,
      rightSideBarVisible: false,
    },
    {
      key: 'dashboard',
      element: (
        <SidebarLabel
          icon={<LayoutDashboard strokeWidth={iconStrokeWidth} />}
          label="Dashboard"
        />
      ),
      node: <Dashboard />,
      rightSideBarVisible: false,
    },
    {
      key: 'profile',
      element: (
        <SidebarLabel
          icon={<User strokeWidth={iconStrokeWidth} />}
          label="Profile"
        />
      ),
      node: <Profile1 />,
      rightSideBarVisible: false,
    },
    {
      key: 'post',
      element: (
        <SidebarLabel
          icon={<SquarePlus strokeWidth={iconStrokeWidth} />}
          label="Post"
        />
      ),
      node: <Post />,
      rightSideBarVisible: false,
    },
  ];

  const { setSelectedNode, setRightSideBarVisible } = useNav();
  const { postDetailsModalProps } = usePostDetailsModalContext();
  return (
    <div
      className={`flex justify-center lg:justify-between bg-red-30 w-[10vw] lg:w-[15vw] max-w-20 min-w-20 lg:max-w-80 min-h-screen items-start
    ${postDetailsModalProps.bgOpacity}`}
    >
      <div className="flex flex-col gap-6  w-full items-center lg:items-start  lg:pl-3 py-4 ">
        {navPages.map((option) => (
          <div
            key={option.key}
            className="flex cursor-pointer"
            onClick={() => {
              setSelectedNode(option.node);
              setRightSideBarVisible(option.rightSideBarVisible);
            }}
          >
            {option.element}
          </div>
        ))}
      </div>
      <div className="w-[1px] h-full min-h-screen bg-slate-300/30"></div>
    </div>
  );
}

export default SideBar;
