import { createContext, ReactNode, useContext, useState } from 'react';

interface EmojiContextProps {
  showEmojiPickerPostIndex: number;
  setShowEmojiPickerPostIndex: (index: number) => void;
}

const EmojiContext = createContext({} as EmojiContextProps);

export function useEmojiContext() {
  return useContext(EmojiContext);
}
export function EmojiProvider(props: Readonly<{ children: ReactNode }>) {
  const { children } = props;
  const [showEmojiPickerPostIndex, setShowEmojiPickerPostIndex] = useState(-1);

  return (
    <EmojiContext.Provider
      value={{
        showEmojiPickerPostIndex,
        setShowEmojiPickerPostIndex,
      }}
    >
      {children}
    </EmojiContext.Provider>
  );
}
