import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useUser } from '@/context/UserContext';

import SigninContentGrid from '@/reactComponents/SigninContentGrid';
import InputButton from '@/reactComponents/InputButton';

import { AUTH_SIGNIN_ENDPOINT } from '@/utils/apiEndpoints';
import httpClient from '@/utils/httpClient';

import { HttpStatusCode } from 'axios';

function Signin(props: { onBackButtonClick: () => void }) {
  const { onBackButtonClick } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setUser } = useUser();

  const handleSignIn = async () => {
    console.log(
      'handleSignInNew called with email:',
      email,
      'password:',
      password,
    );

    const signin = await httpClient(
      'POST',
      AUTH_SIGNIN_ENDPOINT,
      { email, password },
      {
        withCredentials: true,
      },
    );
    if (signin.status === HttpStatusCode.Ok) {
      setUser(signin.data.user);
      navigate('/');
    } else setUser(null);
    console.log('response:', signin);
  };

  return (
    <div className={'relative min-h-screen'}>
      <div className="absolute top-0 left-0 w-full h-full min-h-screen  z-10">
        {/* <SigninContentGrid onBackButtonClick={onBackButtonClick} /> */}
      </div>
      <div className="  h-full min-h-screen flex flex-col justify-end items-center gap-6">
        <div className=" flex flex-col items-center justify-end gap-6 w-full h-min z-20 py-20 bg-gradient-to-t from-black/75 via-black/50 to-black/0 ">
          <InputButton
            inputType={'email'}
            inputPlaceholder={'Email'}
            inputValue={email}
            inputChangeListener={(e) => setEmail(e)}
          />
          <InputButton
            inputType={'password'}
            inputPlaceholder={'Password'}
            inputValue={password}
            inputChangeListener={(e) => setPassword(e)}
          />

          <button
            className="flex justify-center bg-zinc-50   py-2 px-4 rounded-lg 
            hover:bg-transparent hover:outline-none hover:ring-1 hover:ring-white/75 hover:backdrop-blur-sm hover:text-zinc-100
            focus:bg-transparent focus:outline-none focus:ring-1 focus:ring-white/75 focus:backdrop-blur-sm focus:text-zinc-100
          cursor-pointer  duration-700
          bg-gradient-to-r from-orange-500 via-red-600 to-pink-500 text-gray-900/90
          w-full max-w-[220px] md:max-w-[300px] xl:max-w-[400px]"
            onClick={handleSignIn}
          >
            Sign In
          </button>

          <button
            className="flex justify-center bg-zinc-500    py-2 px-4 rounded-lg 
            hover:bg-transparent hover:outline-none hover:ring-1 hover:ring-white/75 hover:backdrop-blur-sm hover:text-zinc-100
            focus:bg-transparent focus:outline-none focus:ring-1 focus:ring-white/75 focus:backdrop-blur-sm focus:text-zinc-100
          cursor-pointer  duration-700
            text-gray-900/90
            w-full max-w-[220px] md:max-w-[300px] xl:max-w-[400px]"
            onClick={() => navigate('/signup')}
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}

export default Signin;
