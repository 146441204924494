import { User } from '@/types/user';
import { AUTH_VERIFY_TOKEN_ENDPOINT } from '@/utils/apiEndpoints';
import axios from 'axios';
import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from 'react';

async function fetchUserFromToken(): Promise<User | null> {
  try {
    const response = await axios.get<User>(AUTH_VERIFY_TOKEN_ENDPOINT, {
      withCredentials: true,
    });
    console.log('response:', response);

    if (response.status === 200) {
      return response.data.user;
    }

    return null;
  } catch (error) {
    console.error('Error fetching user from token:', error);
    return null;
  }
}

type UserContextType = {
  getUser: User | null;
  setUser: (user: User | null) => void;
  logout: () => void;
};

const UserContext = createContext({} as UserContextType);

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider(props: Readonly<{ children: ReactNode }>) {
  const { children } = props;

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    async function loadUser() {
      const fetchedUser = await fetchUserFromToken();
      setUser(fetchedUser);
    }

    loadUser();
  }, []);

  const logout = () => {
    setUser(null);
    // todo : also make a request to the server to invalidate the token
  };

  return (
    <UserContext.Provider
      value={{
        getUser: user,
        setUser,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
