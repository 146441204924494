import { usePostDetailsModalContext } from '@/context/PostDetailsModalContext';
import { X } from 'lucide-react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function PostDetails(props: { children: React.ReactNode }) {
  const { children } = props;
  const { postDetailsModalProps, requestClosePostDetailsModal } =
    usePostDetailsModalContext();

  return (
    <Modal
      isOpen={postDetailsModalProps.isOpen}
      onRequestClose={() => requestClosePostDetailsModal()}
      contentLabel="Example Modal"
      className="modal-content absolute inset-0   flex justify-between w-full min-w-[100vw]
      pl-4 pr-3 py-5
       bg-transparent text-white  border-none outline-none"
      overlayClassName="modal-overlay"
      style={{
        content: {
          top: window.scrollY,
        },
      }}
    >
      <div>{children}</div>
      <X
        className="cursor-pointer"
        onClick={() => requestClosePostDetailsModal()}
      />
    </Modal>
  );
}

export default PostDetails;
