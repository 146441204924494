import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const httpClient = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<any>> => {
  return axios({
    method,
    url,
    data,
    ...config,
  });
};

export default httpClient;
