import { useNavigate } from 'react-router-dom';
import { useUser } from '@/context/UserContext';

function Dashboard() {
  const navigate = useNavigate();
  const { getUser } = useUser();

  return (
    <div className="flex flex-col gap-4 bg-zinc-200 p-8 min-h-screen  items-center">
      <p className="text-2xl text-gray-600">
        Welcome ,
        <span className="text-gray-700 ml-2">{getUser?.firstname}</span>
      </p>

      <p>Your earnings dashboard is under development</p>

      <button
        className="px-5 py-1.5 bg-stone-300 text-slate-600 rounded-lg w-fit hover:bg-stone-100"
        onClick={() => navigate('/')}
      >
        Go to Home Page
      </button>
    </div>
  );
}

export default Dashboard;
