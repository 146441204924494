import ReactDOM from 'react-dom/client';

import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';

import { NavProvider } from './context/NavContext.tsx';

import { EmojiProvider } from './context/EmojiContext.tsx';
import { PostDetailsModalProvider } from './context/PostDetailsModalContext.tsx';
import App from './pages/App.tsx';
import { UserProvider } from './context/UserContext.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <Router>
    <NavProvider>
      <UserProvider>
        <PostDetailsModalProvider>
          <EmojiProvider>
            <App />
          </EmojiProvider>
        </PostDetailsModalProvider>
      </UserProvider>
    </NavProvider>
  </Router>,
  // </React.StrictMode>,
);
