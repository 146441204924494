import { useUser } from '@/context/UserContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SigninComponent(props: { message: string }) {
  const { message } = props;
  const navigate = useNavigate();
  const { getUser } = useUser();
  const [signinButtonMaxWidth, setSigninButtonMaxWidth] =
    useState('max-w-[188px]');

  useEffect(() => {
    if (!getUser) {
      setTimeout(() => {
        setSigninButtonMaxWidth('max-w-[180px]');
      }, 0);
    }
  }, [getUser]);

  return (
    <div className="flex flex-col justify-center items-center pt-40 gap-8 ">
      <p className={`text-zinc-200  font-thin tracking-wider `}>{message}</p>
      <button
        className={`flex justify-center bg-zinc-50   py-2 px-4 rounded-lg 
            hover:bg-transparent hover:outline-none hover:ring-1 hover:ring-white/75 hover:backdrop-blur-sm hover:text-zinc-100
            focus:bg-transparent focus:outline-none focus:ring-1 focus:ring-white/75 focus:backdrop-blur-sm focus:text-zinc-100
          cursor-pointer  duration-700
          bg-gradient-to-r from-orange-500 via-red-600 to-pink-500 text-gray-900/90
          w-full max-w-[180px] ${signinButtonMaxWidth}`}
        onClick={() => navigate('/signin')}
      >
        Sign In
      </button>
    </div>
  );
}

export default SigninComponent;
