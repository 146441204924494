import { useEffect, useState } from 'react';
import axios from 'axios';
import { Smile } from 'lucide-react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { useEmojiContext } from '@/context/EmojiContext';
import PostDetails from './PostDetails';
import { usePostDetailsModalContext } from '@/context/PostDetailsModalContext';

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchPostsAndUsers = async () => {
      try {
        const postsResponse = await axios.get(
          'https://jsonplaceholder.typicode.com/posts',
        );
        const usersResponse = await axios.get(
          'https://jsonplaceholder.typicode.com/users',
        );
        const commentsPromises = postsResponse.data.map((post) => {
          return axios.get(
            `https://jsonplaceholder.typicode.com/posts/${post.id}/comments`,
          );
        });
        const commentsResponses = await Promise.all(commentsPromises);
        const comments = commentsResponses.map((response) => response.data);

        // Update postsResponse.data by appending comments to each post
        const updatedPosts = postsResponse.data.map((post, index) => {
          return {
            ...post,
            comments: comments[index],
          };
        });

        setPosts(updatedPosts);
        setUsers(usersResponse.data);
      } catch (error) {
        console.error('Error fetching posts, users, or comments:', error);
      }
    };

    fetchPostsAndUsers();
  }, []);

  const getUserById = (userId) => {
    return users.find((user) => user.id === userId);
  };

  const { postDetailsModalProps } = usePostDetailsModalContext();
  return (
    <div className="relative flex flex-col  justify-center w-max pb-10 mx-auto">
      {postDetailsModalProps.isOpen && (
        <PostDetails children={<p> Add post details here</p>} />
      )}

      {posts.map((post, index) => {
        const user = getUserById(post.userId);

        return (
          <div
            key={post.id}
            className={`pl-3 pr-20 py-2 max-w-[400px] ${postDetailsModalProps.bgOpacity}`}
          >
            {user && (
              <div className="flex items-center mb-2 px-2 py-1">
                <img
                  src={`https://i.pravatar.cc/150?img=${user.id}`}
                  alt={user.name}
                  className="rounded-full w-10 h-10 mr-2"
                />
                <span>{user.name}</span>
              </div>
            )}
            <img
              src={`https://via.placeholder.com/150?text=Post+${post.id}`}
              alt={`Post ${post.id}`}
              className="rounded-lg w-full max-w-[400px] h-full max-h-[600px] object-cover"
            />
            <PostBody post={post} postIndex={index} />

            {index !== posts.length - 1 && (
              <div className="w-full h-[1px] bg-zinc-300/50 my-6 rounded-lg"></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

function PostBody(
  props: Readonly<{
    post: string;
    postIndex: number;
  }>,
) {
  const { post, postIndex } = props;
  const { requestOpenWithBgOpacity } = usePostDetailsModalContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const isLongPost = post.body.split('\n').length > 2;
  const commentsCount = post.comments.length;

  return (
    <div>
      <div className="flex w-full items-center">
        <p
          className={`py-2 text-sm font-light ${isExpanded ? '' : 'truncate'}`}
        >
          {post.body}
        </p>

        {isLongPost && !isExpanded && (
          <span
            className="text-sm font-light cursor-pointer text-slate-400"
            onClick={() => setIsExpanded(true)}
          >
            {' '}
            more
          </span>
        )}
      </div>
      {commentsCount > 0 && (
        <p
          className="text-sm font-light text-slate-400 cursor-pointer"
          onClick={() => requestOpenWithBgOpacity('opacity-20')}
        >
          View all {commentsCount} comments
        </p>
      )}

      <AddComment postIndex={postIndex} />
    </div>
  );
}

function AddComment(props: { postIndex: number }) {
  const { postIndex } = props;

  const [inputValue, setInputValue] = useState('');

  const { setShowEmojiPickerPostIndex, showEmojiPickerPostIndex } =
    useEmojiContext();

  const handleEmojiSelect = (emoji) => {
    setInputValue(inputValue + emoji.native);
  };

  return (
    <div className="relative flex justify-between w-full h-fit  my-2 p-2 border-none outline-none rounded-lg">
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Add a comment..."
        className="w-full h-full outline-none p-0 m-0 text-sm focus:outline-none selection:outline-none bg-transparent text-white"
      />
      <Smile
        size={16}
        color="#c0c0c0"
        strokeWidth={1.3}
        className="cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          if (
            showEmojiPickerPostIndex != -1 &&
            showEmojiPickerPostIndex === postIndex
          )
            setShowEmojiPickerPostIndex(-1);
          else setShowEmojiPickerPostIndex(postIndex);
        }}
      />
      {showEmojiPickerPostIndex === postIndex && (
        <div className="absolute bottom-full -right-[110%] mb-2 z-10 select-none">
          <Picker data={data} onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
    </div>
  );
}

export default Feed;
