import { useEffect } from 'react';
import SideBar from './SideBar';
import { useNav } from '@/context/NavContext';
import useColorScheme from '@/hooks/useColorScheme';
import toast, { Toaster } from 'react-hot-toast';

import { MAX_FILES_PER_POST } from '@/utils/config';
import RightSideBar from './RightSideBar';
import { usePostDetailsModalContext } from '@/context/PostDetailsModalContext';
import { Route, Routes, useLocation } from 'react-router-dom';
import Signin from './Signin';

function App() {
  useColorScheme();
  const { selectedNode, rightSideBarVisible } = useNav();
  const { postDetailsModalProps } = usePostDetailsModalContext();
  console.log(selectedNode);
  const location = useLocation();
  console.log('location:', location.pathname);

  useEffect(() => {
    if (postDetailsModalProps.isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [postDetailsModalProps.isOpen]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <div className="flex w-full min-w-[100vw] min-h-screen">
              <Toast />

              <div className="w-1/6 sticky top-0 h-screen overflow-y-auto">
                <SideBar />
              </div>
              {rightSideBarVisible && (
                <div className="flex-2 w-3/6">{selectedNode}</div>
              )}
              {!rightSideBarVisible && (
                <div className="flex-2 w-[75%]">{selectedNode}</div>
              )}

              {rightSideBarVisible && (
                <div className="w-2/6">
                  <RightSideBar />
                </div>
              )}
            </div>
          }
        />
        <Route
          path="/signin"
          element={
            <Signin
              onBackButtonClick={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          }
        />
      </Routes>
    </>
  );
}

function Toast() {
  const { showMaxFileUploadToast } = useNav();
  useEffect(() => {
    if (showMaxFileUploadToast) {
      toast.remove();
      toast.custom(<MaxFilesPerPostToast />, {
        duration: 2000,
        position: window.innerWidth <= 768 ? 'top-center' : 'bottom-center',
      });
    }
  }, [showMaxFileUploadToast]);

  return <Toaster />;
}

function MaxFilesPerPostToast() {
  return (
    <div className="flex items-center gap-2 px-6 py-4 mt-2 text-sm font-medium bg-sky-400 rounded-md shadow-lg select-none md:gap-4 md:my-6 md:bg-sky-400/80 shadow-zinc-600 text-white animate-barDown md:animate-barUp">
      {`Please choose upto ${MAX_FILES_PER_POST} files only`}
    </div>
  );
}

export default App;
