import { createContext, ReactNode, useContext, useState } from 'react';

export type PostDetailsModalProps = {
  isOpen: boolean;
  bgOpacity: string;
};
interface ModalContextProps {
  postDetailsModalProps: PostDetailsModalProps;
  requestOpenWithBgOpacity(bgOpacity: string): void;
  requestClosePostDetailsModal: () => void;
}

const ModalContext = createContext({} as ModalContextProps);

export function usePostDetailsModalContext() {
  return useContext(ModalContext);
}
export function PostDetailsModalProvider(
  props: Readonly<{ children: ReactNode }>,
) {
  const { children } = props;
  const [postDetailsModalProps, setPostDetailsModalProps] =
    useState<PostDetailsModalProps>({
      isOpen: false,
      bgOpacity: 'opacity-100',
    });

  return (
    <ModalContext.Provider
      value={{
        postDetailsModalProps,
        requestOpenWithBgOpacity: (bgOpacity: string) => {
          setPostDetailsModalProps({ isOpen: true, bgOpacity });
        },
        requestClosePostDetailsModal: () => {
          setPostDetailsModalProps({ isOpen: false, bgOpacity: 'opacity-100' });
        },
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
