import React, { useState, useRef, useEffect } from 'react';
import { FileImage } from 'lucide-react';
import httpClient from '@/utils/httpClient';

import { CREATE_POST_ENDPOINT } from '@/utils/apiEndpoints';
import { ProgressBar } from 'react-loader-spinner';

import { ImageCarousel } from '@/reactComponents/ImageCarousel';
import { useNav } from '@/context/NavContext';
import { useUser } from '@/context/UserContext';
import SigninComponent from '@/reactComponents/SigninComponent';

function Post() {
  const { getUser } = useUser();
  const [content, setContent] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorDuringPostCreation, setErrorDuringPostCreation] = useState<
    string | null
  >(null);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorDuringPostCreation(null);

    // Create form data
    const formData = new FormData();
    formData.append('content', content);
    files.forEach((file) => {
      formData.append(`files`, file);
    });

    // Send content and files to the backend
    try {
      const response = await httpClient(
        'POST',
        CREATE_POST_ENDPOINT,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        },
      );
      setIsSubmitting(false);

      console.log('Content and files sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending content and files:', error);

      setTimeout(() => {
        const errorMessage = (error as Error).message;
        setErrorDuringPostCreation('Error creating post,' + errorMessage);
        setIsSubmitting(false);
      }, 500);
    }
  };

  if (!getUser) return <SigninComponent message="sign-in to create post" />;
  return (
    <div className="bg-gray-700 rounded-md w-full h-fit mx-4 my-2 px-5 py-6">
      <p className="flex justify-center items-center">Create new post</p>
      <form onSubmit={handleSubmit}>
        <textarea
          autoFocus
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className={`w-full py-2 px-3  outline-none text-white resize-none bg-slate-500 rounded-lg mt-4 mb-6 ${isSubmitting ? 'pointer-events-none opacity-55' : 'opacity-100'}`}
          placeholder="Say Hello..."
          rows={4}
          readOnly={isSubmitting}
        />

        <div className="mb-10">
          <SelectedFilesUI
            files={files}
            setFiles={setFiles}
            isSubmitting={isSubmitting}
          />
        </div>
        {!isSubmitting && (
          <div className="flex justify-between pr-2 md:pr-4 lg:pr-10 items-center h-10">
            <FileUploadUI isSubmitting={isSubmitting} setFiles={setFiles} />
            <button
              className="bg-sky-400 px-4 py-2 rounded-lg"
              onClick={handleSubmit}
            >
              Post
            </button>
          </div>
        )}
        {isSubmitting && (
          <div className="flex justify-center items-center h-10">
            <p className="text-xs font-light text-[rgb(158,156,169)] text-zinc-300/80">
              Creating Post
            </p>
            <ProgressBar
              visible={true}
              height="38"
              borderColor="rgb(158 156 169)"
              barColor="rgb(188 186 199)"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
      </form>
      {errorDuringPostCreation && (
        <p className="flex justify-center items-center text-xs font-semibold text-red-400 mt-5 mb-8">
          {errorDuringPostCreation}
        </p>
      )}
    </div>
  );
}

function SelectedFilesUI(props: {
  files: File[];
  setFiles: (files: File[]) => void;
  isSubmitting: boolean;
}) {
  const { files, setFiles, isSubmitting } = props;
  const [playing, setPlaying] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    // Cleanup object URLs when component unmounts
    return () => {
      files.forEach((file) => {
        const fileURL = URL.createObjectURL(file);
        URL.revokeObjectURL(fileURL);
      });
    };
  }, [files]);

  if (files.length === 0) {
    return null;
  }

  const handlePlayClick = (index: number) => {
    if (isSubmitting) return;

    setPlaying((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleRemoveFile = (index: number, event: React.MouseEvent) => {
    if (isSubmitting) return;

    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Prevent form submission
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  return (
    <div className={`mt-4 ${isSubmitting ? 'opacity-50' : 'opacity-100'}`}>
      <ImageCarousel
        files={files}
        handleRemoveFile={handleRemoveFile}
        handlePlayClick={handlePlayClick}
        playing={playing}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}

function FileUploadUI(
  props: Readonly<{
    isSubmitting: boolean;
    setFiles: (files: File[]) => void;
  }>,
) {
  const { isSubmitting, setFiles } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setshowMaxFileUploadToast } = useNav();

  const handleIconClick = () => {
    setshowMaxFileUploadToast(false);

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files.length > 6) {
        setshowMaxFileUploadToast(true);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        setFiles(Array.from(e.target.files));
        setshowMaxFileUploadToast(false);
      }
    }
  };

  return (
    <div className="flex items-center">
      <div
        className={`my-2 mx-4 rounded cursor-pointer focus:outline-none focus:shadow-outline ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleIconClick}
        title="media"
      >
        <FileImage className="hover:opacity-50" />
      </div>
      <input
        id="file-upload"
        type="file"
        accept="image/*, video/*, .gif"
        className="hidden"
        onChange={handleFileChange}
        disabled={isSubmitting}
        multiple
        ref={fileInputRef}
      />
    </div>
  );
}

export default Post;
